import React from "react";
import { ContactUsDiv } from "../Styles/ScreensStyles/contact.style";
import GeneralSubHeroSection from "../Components/generalSubHeroSection";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { TbWorld } from "react-icons/tb";
import { FaWhatsapp } from "react-icons/fa";

const linkDetails = [
  { icon: MdEmail, title: "Email", content: "support@ojirehprime.com" },
  { icon: TbWorld, title: "Website", content: "olijeh.com" },
  {
    icon: MdLocationPin,
    title: "Headquaters",
    content: "3rd Floor 86-90 Paul Street London EC2A 4NE",
  },
  {
    icon: MdLocationPin,
    title: "Address 2",
    content: `12, Montgomery road,
    off Herbert Macaulay,
    yaba, Lagos State.`,
  },
  { icon: FaWhatsapp, title: "WhatsApp", content: "+234 906 914 4499" },
];

const Contactus = () => {
  return (
    <div>
      <GeneralSubHeroSection title={"Contact us"} />

      <ContactUsDiv>
        <h3>We are here always and we would like to hear from you.</h3>

        <div className="contact_us_wrapper">
          <div className="contact_us_wrapper_details">
            {linkDetails?.map((item, key) => (
              <div className="contact_us_wrapper_details_content" key={key}>
                <div className="contact_us_wrapper_details_content_icon_body">
                  <item.icon className="contact_us_wrapper_details_content_icon" />
                </div>
                <div className="contact_us_wrapper_details_content_details">
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="contact_us_wrapper_form">
            <div className="contact_us_wrapper_form_inputs">
              <div className="contact_us_wrapper_form_inputs_card">
                <label>Subject</label>
                <InputText
                  value={""}
                  onChange={(e) => {}}
                  placeholder="The reason you are contacting us"
                />
              </div>

              <div className="contact_us_wrapper_form_inputs_card">
                <label>Email</label>
                <InputText
                  value={""}
                  onChange={(e) => {}}
                  placeholder="Enter your email address"
                />
              </div>

              <div className="contact_us_wrapper_form_inputs_card">
                <label>Message</label>
                <InputTextarea
                  autoResize
                  value={""}
                  onChange={(e) => {}}
                  rows={5}
                  cols={30}
                  placeholder="What will you like us to do for you"
                />
              </div>
            </div>

            <div>
              <Button label="Submit" />
            </div>
          </div>
        </div>
      </ContactUsDiv>
    </div>
  );
};

export default Contactus;
