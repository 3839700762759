import React from "react";
import HeroSection from "../Components/LandingPageComponent/HeroSection";
import Aboutussub from "../Components/AboutusComponent/about_us_sub";
import Photo from "../Assets/appview.JPG";
import PhotoTwo from "../Assets/img1.JPG";
import PhotoThree from "../Assets/img10.JPG";

const About_us = () => {
  return (
    <div>
      <HeroSection
        header={"About Us"}
        content={
          "We have collated together our top most frequently asked questions in hope that all or one of them would provide you the answer you are looking for. If you can not find the right answers here, do not hesitate to "
        }
        checkLead={false}
      />

      <Aboutussub
        image={Photo}
        image2={PhotoTwo}
        image3={PhotoThree}
        header={`IT ALL STARTED FROM A FAULTY SAMSUNG TAB...`}
        subHeader={`A bit of History`}
        content={`We started as an e-commerce company in Nov 2016 from a faulty samsung tab whose battery lasted only 30mins. One Year later we realised that only 4% of payments were made using the debit card for fear of falling victim to card frauds. 96% of payments were made via direct fund transfers. To address this - in 2018, we launched Ojirehprime card which is a prepaid card that is not connected to your bank account and within two years, we grew organically over 40,000 users.

In our next survey where we interviewed over 100,000 millennials, a staggering 97% believe that traditional banks are not transparent, have poor customer experience and watching a football match offers a better experience than walking into a bank. This is where our journey to build a bank you’ll be proud to call Family began.`}
      />
    </div>
  );
};

export default About_us;
