import styled from "styled-components";

export const FooterStyledDiv = styled.div`
  width: 100%;
  padding: 60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;

  .footer_wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;

    .footer_header {
      width: 60%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px;

      h3 {
        color: #363636;
        font-size: 32px;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        margin: 0;
        text-align: center;
      }

      .footer_header_images {
        width: 60%;
        padding: 0px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 40%;
        }

        @media screen and (max-width: 840px) {
          width: 100%;
          padding: 0px;
        }
      }

      @media screen and (max-width: 1099px) {
        width: 80%;
      }

      @media screen and (max-width: 840px) {
        width: 100%;
      }
    }

    .footer_body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .footer_body_title {
        width: 70%;
        text-align: center;

        p {
          margin: 0;
          color: #2c3e50;
          font-size: 13px;
          font-family: "Inter", sans-serif;
          font-weight: 400;
          line-height: 18px;
        }

        @media screen and (max-width: 840px) {
          width: 80%;
        }

        @media screen and (max-width: 740px) {
          width: 100%;
        }
      }

      .footer_body_link {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        .footer_body_section {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .footer_body_section_header {
            h4 {
              margin: 0;
              color: #2c3e50;
              font-size: 20px;
              font-family: "Inter", sans-serif;
              font-weight: 600;
              line-height: 18px;
            }
          }

          .footer_body_section_content {
            display: flex;
            flex-direction: column;
            gap: 8px;

            a {
              color: #272727;
              text-decoration: none;

              p {
                margin: 0;
                color: #272727;
                font-size: 16px;
                font-family: "Inter", sans-serif;
                font-weight: 400;
              }
            }

            .footer_body_section_content_item {
              display: flex;
              align-items: center;
              gap: 20px;

              .footer_body_section_content_item_icon_body {
                display: flex;
                align-items: center;
                justify-content: center;

                .footer_body_section_content_item_icon {
                  color: #272727;
                  font-size: 16px;
                }
              }

              p {
                margin: 0;
                color: #272727;
                font-size: 16px;
                font-family: "Inter", sans-serif;
                font-weight: 400;
              }
            }
          }

          @media screen and (max-width: 840px) {
            width: 30%;
            margin-bottom: 30px;
          }

          @media screen and (max-width: 600px) {
            width: 45%;
          }

          @media screen and (max-width: 420px) {
            width: 100%;
          }
        }
      }
    }

    .footer_footer {
      p {
        margin: 0;
        color: #2c3e50;
        font-size: 16px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
      }
    }

    @media screen and (max-width: 740px) {
      width: 95%;
    }
  }
`;
