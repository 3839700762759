import styled from "styled-components";

export const ContactUsDiv = styled.div`
  width: 100%;
  padding: 90px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  text-align: center;

  h3 {
    color: #4a4a4a;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    line-height: 20px;
  }

  .contact_us_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .contact_us_wrapper_details {
      width: 55%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;

      .contact_us_wrapper_details_content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 15px;

        .contact_us_wrapper_details_content_icon_body {
          display: flex;
          align-items: center;
          justify-content: center;

          .contact_us_wrapper_details_content_icon {
            color: #008000;
            font-size: 32px;
          }
        }

        .contact_us_wrapper_details_content_details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;

          h3 {
            color: #363636;
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            font-family: "Inter", sans-serif;
            line-height: 20px;
          }

          p {
            color: #272727;
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            font-family: "Inter", sans-serif;
            line-height: 20px;
            text-align: left;
          }
        }
      }

      @media screen and (max-width: 740px) {
        width: 100%;
      }
    }

    .contact_us_wrapper_form {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      .contact_us_wrapper_form_inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .contact_us_wrapper_form_inputs_card {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3px;

          label {
            color: #363636;
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            line-height: 20px;
          }

          input {
            outline: none;
            width: 100%;
          }

          textarea {
            outline: none;
            width: 100%;
          }
        }
      }

      @media screen and (max-width: 740px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 740px) {
      flex-direction: column;
      gap: 100px;
    }
  }

  @media screen and (max-width: 990px) {
    padding: 90px 100px;
  }

  @media screen and (max-width: 740px) {
    padding: 90px 50px;
  }

  @media screen and (max-width: 500px) {
    padding: 90px 20px;
  }
`;
