import React from "react";
import PhoneOne from "../../Assets/Transfer.jpg.png";
import { CardStyledDiv } from "../../Styles/ComponentStyles/LandingPageComponentStyles/CardFlex.style";

const CardStyle = ({
  image,
  header,
  subHeader,
  content,
  wrappStyle,
  indicatior,
}) => {
  return (
    <CardStyledDiv indicatior={indicatior} style={{ ...wrappStyle }}>
      <div className="card_wrapper">
        <div className="card_wrapper_image_section">
          <img src={image} />
        </div>
        <div className="card_wrapper_content_wrapper">
          <div className="card_wrapper_content">
            <div className="card_wrapper_content_header">
              <h4>{header}</h4>
              <h3>{subHeader}</h3>
            </div>
            <div className="card_wrapper_content_paragraph">
              <p>{content}</p>
            </div>
          </div>
        </div>
      </div>
    </CardStyledDiv>
  );
};

export default CardStyle;
