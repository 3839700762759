import styled from "styled-components";

export const AccordionsDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 130px 0px;

  .accordion_wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 740px) {
      width: 95%;
    }
  }
`;
