export const TermsAndConditionDetails = [
  {
    title: "Who are We?",
    privacyList: [
      {
        titleWithoutCount:
          "OjirehPrime Financial Services Ltd is a financial technology company providing digital financial Platforms with savings accounts powered by Solid Allianze Microfinance Bank Ltd. A fully licensed Microfinance bank by the Central bank of Nigeria with deposits insured by the Nigerian Deposit insurance Corporation. Headquartered at 86-90 Paul Street, EC2A 4NE. London with African Operations at No. 12 Montgomery road. Yaba. Lagos State. Nigeria",
      },
    ],
  },
  {
    title: "Acceptance of the terms of use",
    privacyList: [
      {
        titleWithoutCount:
          "If you accept these conditions, you are giving us permission to use the information you provide so that we can send and receive payments related to your account. If you decide that it is no longer acceptable for us to utilize the information you have provided, we will be forced to close your account. However, we reserve the right to store any personal information you provide and to make use of it when we have a legitimate reason to do so. For instance, any records that we are required to maintain for purposes of regulation.",
      },
    ],
  },
  {
    title: "Who holds your Money",
    privacyList: [
      {
        titleWithoutCount:
          "All accounts are provided by Solid Allianze Microfinance Bank which means that we do not hold your deposits, we’ve rather partnered with Solid Allianze Microfinance Ltd by providing technology to simplify banking services.",
      },
    ],
  },
  {
    title: "Changes to the terms of use",
    privacyList: [
      {
        titleWithoutCount:
          "We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the mobile application (OjirehPrime App or Website) thereafter.",
      },
    ],
  },
  {
    title: "Accessing the website and account security",
    privacyList: [
      {
        titleWithoutCount:
          "We retain the right, in our sole discretion and without notice, to discontinue or make changes to our mobile applications (OjirehPrime App or website) and any service or material we provide on the mobile application (OjirehPrime App or website). If the OjirehPrime App or website is inaccessible at any moment or for any period, we assume no responsibility and will not refund any fees paid. We have the right to make certain features of the mobile app available only to registered users at certain times.",
      },
      {
        titleWithoutCount:
          "You may be required to give registration data or other information in order to use the mobile application (OjirehPrime App or website) or some of the resources it provides. Your usage of the mobile application is contingent upon your ability to supply information that is true, accurate, current, and complete. You acknowledge that our Privacy Policy governs our use of any and all information you provide to us in connection with this mobile application, whether in the course of registering for or using the mobile application, or in any other way.",
      },
      {
        titleWithoutCount:
          "You undertake to treat as strictly private and confidential any user name, password, or other piece of information that is part of our security measures, whether chosen by you or provided by us. You also agree not to share your username, password, or any other security information that enables access to this mobile application (OjirehPrime App or website) with any other person. If you suspect your username or password has been compromised in any way, you must contact us immediately. You further agree that you will log out of your account each time you use it. If you must access your account from a public or shared computer, be extra vigilant in protecting the secrecy of your password and other personal information by taking appropriate measures.",
      },
      {
        titleWithoutCount:
          "If we believe that you have violated any part of these Terms of Use, we have the right to disable any user name, password, or other identification, whether it was chosen by you or was provided by us.",
      },
    ],
  },
  {
    title: "Intellectual property rights",
    privacyList: [
      {
        titleWithoutCount:
          "All rights to our site and the content presented on it are either owned by us or protected under license. Copyright laws and conventions all across the world safeguard these works. We reserve the right to do so. In accordance with these Terms of Service, you may only access and use the OjirehPrime App or website for its intended purposes. You may not use, copy, download, repost, store, or transmit any of the content found on our mobile application (OjirehPrime App or website) without our prior written permission.",
      },
      {
        titleWithoutCount:
          "Your permission to use the mobile application (OjirehPrime App or website) will automatically terminate and you will be required to return or destroy any copies you have made if you have printed, copied, modified, downloaded, or otherwise used or provided access to any part of the mobile application (OjirehPrime App or website) in violation of the Terms of Use. All rights not expressly granted are reserved by the Company, including any and all intellectual property rights related to the mobile application (OjirehPrime App or website) or any material on the mobile application (OjirehPrime App or website). The OjirehPrime App and website are protected by copyright, trademark, and other laws that may be infringed upon by unauthorised uses of the mobile application.",
      },
    ],
  },
  {
    title: "Prohibited Uses",
    privacyList: [
      {
        titleWithoutCount:
          "The mobile app is for your personal, lawful use only, and in compliance with these Terms of Use. You pledge to abstain from the following uses of the OjirehPrime App or website:",

        privacyList: [
          {
            title:
              "In any way that is illegal under the laws of the Fedreal republic of Nigeria, the United Kingdom, United states of America or any other country.",
          },
          {
            title:
              "To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).",
          },
          {
            title:
              "To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the mobile application (OjirehPrime App or website), or which, as determined by us, may harm the Company or users of the mobile application (OjirehPrime App or website) or expose them to liability.",
          },
        ],
      },
      {
        title: "Additionally, you agree not to:",
        privacyList: [
          {
            title:
              "Use the mobile application (OjirehPrime App or website) in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the mobile application (OjirehPrime App or website), including their ability to engage in real time activities through the mobile application (OjirehPrime App or website).",
          },
          {
            title:
              "Use any robot, spider or other automatic device, process or means to access the mobile application (OjirehPrime App or website) for any purpose, including monitoring or copying any of the material on the mobile application (OjirehPrime App or website).",
          },
          {
            title:
              "Use any manual process to monitor or copy any of the material on the mobile application (OjirehPrime App or website) or for any other unauthorized purpose without our prior written consent.",
          },
          {
            title:
              "Use any device, software or routine that interferes with the proper working of the mobile application (OjirehPrime App or website).",
          },
          {
            title:
              "Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.",
          },
          {
            title:
              "Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the mobile application (OjirehPrime App or website), the server on which the mobile application (OjirehPrime App or website) is stored, or any server, computer or database connected to the mobile application (OjirehPrime App or website).",
          },
          {
            title:
              "Attack the mobile application (OjirehPrime App or website) via a denial-of-service attack or a distributed denial-of-service attack.",
          },
          {
            title:
              "Otherwise attempt to interfere with the proper working of the mobile application (OjirehPrime App or website).",
          },
        ],
      },
    ],
  },
  {
    title: "Online content should not be relied upon",
    privacyList: [
      {
        titleWithoutCount:
          "For informational purposes only, we provide the content found on or through the OjirehPrime App or website. We make no claims about this data's reliability, completeness, or performance. You should not put any stock into such information. You, any other user of the OjirehPrime App or website, and anyone who may be informed by any of its contents are not allowed to rely on any such materials, and we expressly disclaim any and all liability and obligation resulting from any such reliance.",
      },
    ],
  },
  {
    title:
      "Information about you and your visits to the mobile application (OjirehPrime App or website)",

    privacyList: [
      {
        titleWithoutCount:
          "Our privacy policy applies to any and all data gathered by the OjirehPrime App or website. If you use the OjirehPrime App or website, you agree that we may collect, use, disclose, and otherwise process your personal data in the ways described in the Privacy Policy.",
      },
    ],
  },
  {
    title: "Access to password protected/secure areas",
    privacyList: [
      {
        titleWithoutCount:
          "Only authorised users are allowed access to and usage of password protected and/or secure portions of the mobile application (OjirehPrime App or website). OjirehPrime App or website users who attempt to access these restricted areas without proper authorization may face legal consequences.",
      },
    ],
  },
  {
    title: "Use of 'cookie' file features",
    privacyList: [
      {
        titleWithoutCount: `To better tailor the mobile application (OjirehPrime App or website) to individual users' tastes, the Company may place a "cookie" or similar file on a user's computer.`,
      },
      {
        titleWithoutCount: `Links to other sites and resources offered by third parties are included in the mobile application (OjirehPrime App or website) for your convenience only. Advertisements such as banner ads and sponsored links are also included in this category. We are not responsible for the availability of these sites or resources or for any loss or damage that may result from your use of them. Your use of any third-party mobile application (OjirehPrime App or website) linked to this mobile application (OjirehPrime App or website) is at your sole risk and is governed by the terms and conditions of use for such mobile application (OjirehPrime App or website).`,
      },
    ],
  },

  {
    title: "Disclaimer of Warranties",
    privacyList: [
      {
        titleWithoutCount:
          "You acknowledge that there is no way to ensure the safety of any file downloaded from the internet or the mobile application (OjirehPrime App or website) and that we make no representations or warranties to that effect. You must put in place adequate protections against viruses and ensure the integrity and accuracy of all data input and output, as well as keep a separate mechanism, outside of our site, to reconstruct any data that you may have lost.",
      },
      {
        titleWithoutCount: `Your use of the mobile application (OjirehPrime App or website) or any services or items obtained through the mobile application (OjirehPrime App or website) or to your downloading of any material posted on it, or on any website linked to it, is at your own risk and we will not be responsible for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material.`,
      },
      {
        titleWithoutCount: `Use of the OjirehPrime App or website, its content, and any services or items obtained through the OjirehPrime App or website are done so at your own risk. The OjirehPrime App or website, its content, and any services or items obtained through the OjirehPrime App or website are provided "AS IS" and "AS AVAILABLE" and without any warranties of any kind, either express or implied. (OjirehPrime App or website). FURTHERMORE, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE MOBILE APPLICATION (OjirehPrime APP OR WEBSITE), ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE MOBILE APPLICATION (OjirehPrime APP OR WEBSITE) WILL BE ACCURATE, RELIABLE, ERROR-FREE.`,
      },
    ],
  },
  {
    title: `Limitation on Liability`,
    privacyList: [
      {
        titleWithoutCount: `IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE mobile application (OjirehPrime App or website), ANY mobile application (OjirehPrime App or website)S LINKED TO IT, ANY CONTENT ON THE mobile application (OjirehPrime App or website) OR SUCH OTHER mobile application (OjirehPrime App or website)S OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE mobile application (OjirehPrime App or website) OR SUCH OTHER mobile application (OjirehPrime App or website)S, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. The above does not apply to liability that cannot be excluded or limited by law.`,
      },
    ],
  },
  {
    title: "Indemnification",
    privacyList: [
      {
        titleWithoutCount: `You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and their officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the mobile (OjirehPrime App or website).`,
      },
    ],
  },
  {
    title: `Limitation on time to file claims`,
    privacyList: [
      {
        titleWithoutCount: `ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE mobile application (OjirehPrime App or website) MUST BE COMMENCED WITHIN ONE YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE IT IS PERMANENTLY BARRED.`,
      },
    ],
  },
  {
    title: `Waiver and Severability`,
    privacyList: [
      {
        titleWithoutCount: `Any failure by the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.`,
      },
      {
        titleWithoutCount: `If a court or other tribunal of competent jurisdiction finds any provision of these Terms of Use invalid, illegal, or unenforceable, it shall be eliminated or limited to the minimum extent necessary to preserve the remaining provisions.`,
      },
    ],
  },
  {
    title: "Your comments and concerns",
    privacyList: [
      {
        titleWithoutCount: `All other feedback, comments, requests for technical support and other communications relating to the mobile application (OjirehPrime App or website) should be directed to: support@ojirehprime.com .`,
      },
    ],
  },
];
