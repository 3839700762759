import React from "react";
import { GeneralSubHeroSectionDiv } from "../Styles/ComponentStyles/generalSubHero.style";

const GeneralSubHeroSection = ({ title }) => {
  return (
    <GeneralSubHeroSectionDiv>
      <h3>{title}</h3>
    </GeneralSubHeroSectionDiv>
  );
};

export default GeneralSubHeroSection;
