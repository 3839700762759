import React from "react";
import GeneralSubHeroSection from "../Components/generalSubHeroSection";
import { PrivacyDiv } from "../Styles/ScreensStyles/privacy.style";
import { PrivacyDetails } from "../utils/privacyDetails";

const PrivacyPolicy = () => {
  return (
    <div>
      <GeneralSubHeroSection title={"Privacy and Data Policy"} />

      <PrivacyDiv>
        <p className="privacy_state">
          {`We are OjirehPrime Financial Services Ltd (hereinafter: ‘OjrehPrime’,
          we’, ‘our’, ‘us’) and operate under the name OjirehPrime. This privacy
          policy (Policy’) describes how OjirehPrime collects, uses, and
          processes your personal information, including the information you
          provide on this website, www.ojirehprime.com, (‘Website’), our mobile
          and web applications (‘App’), as well as the email messages we send to
          and receive from you (‘Emails’). It also describes the choices
          available to you regarding our use of your personal information, in
          providing you with a platform to carry out digital banking services,
          and your use of any of our products (the ‘Services’). Please read this
          Policy carefully as it is legally binding when you use our Services.
          In this context, ‘personal information’ means all detailed information
          about personal or factual circumstances of a specific or identifiable
          natural person, such as name, telephone number or address. For the
          purpose of the relevant data protection regulations, the company in
          charge of your information (also known as ‘data controller’) is
          OjirehPrime Financial Services Ltd of 3rd Floor, 86-90 Paul Street,
          London EC2A 4NE, United Kingdom. If you have any questions about this
          Policy, please email us at`}{" "}
          <a href="/">privacy@ojirehprime.com</a>.{" "}
          {`By continuing
          your interactions with us, such as by submitting information to us, or
          using our Services, you confirm that you understand and consent to the
          collection, use, disclosure, and processing of your personal
          information (or the personal information of any individual you
          provide) as described in this Policy.`}
        </p>

        <div className="privacy_content">
          {PrivacyDetails?.map((item, key) => (
            <div key={key}>
              <h3 className="privacy_content_title">
                {`${key + 1}. ${item?.title}`}
              </h3>
              <div className="privacy_subContent">
                {item?.privacyList?.map((data, index) => (
                  <div key={index}>
                    {data?.titleWithoutCount ? (
                      <p className="privacy_subContent_heading">
                        {data?.titleWithoutCount}
                      </p>
                    ) : !data?.privacyList ? (
                      <>
                        <p className="privacy_subContent_List">
                          {`${key + 1}.${index + 1}. `}
                          {data?.contentTitle && (
                            <span className="privacy_subContent_List_title">
                              {`${data?.contentTitle} `}
                            </span>
                          )}
                          {data?.title}
                        </p>
                      </>
                    ) : (
                      <div className="privacy_subContent_List_conatiner">
                        <p className="privacy_subContent_List">
                          {`${key + 1}.${index + 1}. `}
                          {data?.contentTitle && (
                            <span className="privacy_subContent_List_title">
                              {`${data?.contentTitle} `}
                            </span>
                          )}
                          {data?.title}
                        </p>
                        <div className="privacy_subContent_List_sub_container">
                          {data?.privacyList?.map((res, ind) => (
                            <p
                              key={ind}
                              className="privacy_subContent_List_sub"
                            >
                              {`(${key + 1}.${index + 1}.${ind + 1}). ${
                                res?.title
                              }`}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </PrivacyDiv>
    </div>
  );
};

export default PrivacyPolicy;
