import React, { useState } from "react";
import { NavLinks } from "../../utils/navLinks";
import { NavDiv } from "../../Styles/ComponentStyles/NavStyles/nav.style";
import { FiMenu } from "react-icons/fi";
import Logo from "../../Assets/logos.png";
import { NavLink } from "react-router-dom";

const Nav = () => {
  const [show, setShow] = useState(false);

  return (
    <NavDiv show={show}>
      <div className="nav_container">
        <div className="nav_container_fronContainer">
          <NavLink to={"/"} className="nav_container_logo">
            <img src={Logo} />

            {/* <h4>OjirehPrime</h4> */}
          </NavLink>

          <div
            className="nav_container_logo_menu"
            onClick={() => setShow(!show)}
          >
            <FiMenu className="nav_menu" />
          </div>
        </div>

        <div className="nav_main_body_wrapper">
          <div className="nav_main_body">
            {NavLinks?.map((item, key) => (
              <NavLink to={item.link} className={"nav"} key={key}>
                <p>{item?.title}</p>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </NavDiv>
  );
};

export default Nav;
