import React from "react";
import { AboutUsDiv } from "../../Styles/ComponentStyles/AboutUsStyles/aboutus.style";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import OwlCarousel from "react-owl-carousel";

const Aboutussub = ({
  image,
  image2,
  image3,
  header,
  subHeader,
  content,
  wrappStyle,
  styles,
}) => {
  return (
    <AboutUsDiv>
      <div className="card_wrapper" style={{ ...styles }}>
        <div className="classmajorwrapper">
          {/* <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          > */}
          <OwlCarousel
            margin={30}
            loop
            nav={true}
            // navContainer={"owl-navs"}
            // navClass={["owl-prevs", "owl-next"]}
            autoplay={true}
            autoplayTimeout={5000}
            autoplayHoverPause={true}
            smartSpeed={1000}
            lazyLoad={true}
            items={1}
            className="card_wrapper_image_section"
          >
            <div class="item" className="card_wrapper_image_section">
              <img src={image} />
            </div>

            <div class="item" className="card_wrapper_image_section">
              <img src={image2} />
            </div>

            <div class="item" className="card_wrapper_image_section">
              <img src={image3} />
            </div>
          </OwlCarousel>
          {/* </Swiper> */}
        </div>

        <div className="card_wrapper_content_wrapper">
          <div className="card_wrapper_content">
            <div className="card_wrapper_content_header">
              <h4>{header}</h4>
              <h3>{subHeader}</h3>
            </div>
            <div className="card_wrapper_content_paragraph">
              <p>{content}</p>
            </div>
          </div>
        </div>
      </div>
    </AboutUsDiv>
  );
};

export default Aboutussub;
