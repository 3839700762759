import React from "react";
import { TermsAndConditionDetailsDiv } from "../Styles/ScreensStyles/termsAndCondition.style";
import { SecurityDetails } from "../utils/securityDetails";
import GeneralSubHeroSection from "../Components/generalSubHeroSection";

const Security = () => {
  return (
    <div>
      <GeneralSubHeroSection title={"How Olijeh Protects You"} />

      <TermsAndConditionDetailsDiv>
        <div className="privacy_content">
          {SecurityDetails?.map((item, key) => (
            <div key={key}>
              <h3 className="privacy_content_title">
                {`${key + 1}. ${item?.title}`}
              </h3>
              <div className="privacy_subContent">
                {item?.privacyList?.map((data, index) => (
                  <div key={index}>
                    {data?.titleWithoutCount && (
                      <p className="privacy_subContent_heading">
                        {data?.titleWithoutCount}
                      </p>
                    )}
                    {(data?.contentTitle ||
                      data?.privacyList ||
                      data?.title) && (
                      <>
                        {!data?.privacyList ? (
                          <>
                            <p className="privacy_subContent_List">
                              {`${key + 1}.${index + 1}. `}
                              {data?.contentTitle && (
                                <span className="privacy_subContent_List_title">
                                  {`${data?.contentTitle} `}
                                </span>
                              )}
                              {data?.title}
                            </p>
                          </>
                        ) : (
                          <div className="privacy_subContent_List_conatiner">
                            <p className="privacy_subContent_List">
                              {`${key + 1}.${index + 1}. `}
                              {data?.contentTitle && (
                                <span className="privacy_subContent_List_title">
                                  {`${data?.contentTitle} `}
                                </span>
                              )}
                              {data?.title}
                            </p>
                            <div className="privacy_subContent_List_sub_container">
                              {data?.privacyList?.map((res, ind) => (
                                <p
                                  key={ind}
                                  className="privacy_subContent_List_sub"
                                >
                                  {`(${key + 1}.${index + 1}.${ind + 1}). ${
                                    res?.title
                                  }`}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </TermsAndConditionDetailsDiv>
    </div>
  );
};

export default Security;
