export const SecurityDetails = [
  {
    title: `Your Identity Documents`,
    privacyList: [
      {
        titleWithoutCount: `We ask everyone who opens a Olijeh account to give us their BVN (Bank Verification Number) and a valid ID issued by the government (driver’s license, voter’s card, national ID card or NIN slip). This helps us prevent identity fraud by confirming that everyone who uses Olijeh, including you, is who they say they are.`,
      },
    ],
  },
  {
    title: `Your Facial Identity`,
    privacyList: [
      {
        titleWithoutCount: `It’s mandatory for anyone opening a Olijeh account to take a photo of their face during the account opening process so that we can confirm they’re opening the account themselves. This also helps prevent identity fraud.`,
      },
    ],
  },
  {
    title: `Your Password`,
    privacyList: [
      {
        titleWithoutCount: `When you’re opening your Ollijeh account, we ask that you create a password (with any combination of letters, numbers and special characters) to secure your account. We may ask for your password to authorise any changes you make to your account information.`,
      },
    ],
  },
  {
    title: `Your PINs`,
    privacyList: [
      {
        titleWithoutCount: `Every Ollijeh account is secured with a unique six-digit PIN that only the account holder should have access to. You can’t use your Ollijeh account without first setting your sign-in PIN. Your transactions on the app are also secured with a transaction PIN, and your Ollijeh Card has its own four-digit PIN.`,
      },
    ],
  },
  {
    title: `Biometrics`,
    privacyList: [
      {
        titleWithoutCount: `The Ollijeh app is enabled for biometrics (fingerprint and Face ID options) to add an extra layer of security when you’re signing in to the app or authorising transactions, if your device has a fingerprint scanner or Face ID.`,
      },
    ],
  },
  {
    title: `Confirming Your Unsaved Beneficiaries`,
    privacyList: [
      {
        titleWithoutCount: `When you’re sending money to a beneficiary you haven’t saved, we ask you to confirm that you’re sure you want to make the transfer. We do this to give you some time to double check that you’re not being scammed - completed transfers are almost impossible to reverse.`,
      },
    ],
  },
  {
    title: `Transaction Alerts`,
    privacyList: [
      {
        titleWithoutCount: `We send you real time alerts (in the form of app notifications and emails) for transactions on your account. This keeps you up to date on what’s happening with your money.`,
      },
    ],
  },
  {
    title: `Card Control`,
    privacyList: [
      {
        titleWithoutCount: `On the Ollijeh app, you can choose where your Ollijeh Card works - POS, online and/or ATM. If your card is missing or you suspect that it has been stolen, you can block or cancel your card on the app so that no one can use it.`,
      },
    ],
  },
  {
    title: `3D Secure (3DS) Card Payments`,
    privacyList: [
      {
        titleWithoutCount: `3D Secure is an extra layer of security for card payments you make online. When paying with your card online, we’ll send you a code by SMS and/or email to authorise the payment.`,
      },
    ],
  },
  {
    title: `Your Personal Information`,
    privacyList: [
      {
        titleWithoutCount: `We use the highest level of encryption to protect your personal information including your identity and confidential account details. We don’t share your information with unauthorised third parties, we would never sell your information and we don’t use your BVN to access any other bank account(s) you may have.`,
      },
    ],
  },
  {
    title: `Insuring Your Money`,
    privacyList: [
      {
        titleWithoutCount: `We insure all deposits you make into your Ollijeh Microfinance Bank account with the National Deposit Insurance Commission (NDIC).`,
      },
      {
        titleWithoutCount: `If you make an insurance claim on a deposit and the claim is successful, the Deposit Insurance Fund of the NDIC is expected to pay you up to 200,000 naira in compensation.`,
      },
    ],
  },
];
