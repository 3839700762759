export const PrivacyDetails = [
  {
    title: "How do we protect your personal information",
    privacyList: [
      {
        title:
          "The security of your personal information is important to us. All the information you provide to us is stored on our secure servers. When you enter certain information including payment transactions, we encrypt such information using reasonable security measures in line with generally acceptable industry standards.",
      },
      {
        title:
          "Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Services, you are responsible for keeping this password confidential. We ask you not to share this password with anyone else and not to use this password for other services or products. Your password is stored on OjirehPrime’s servers in an encrypted form. We may however disclose your first name and the first letter of your last name to other users, who are transacting with you via the Services.",
      },
      {
        title:
          "As you will know, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your personal information during transmission; any transmission is at your own risk and outside our control. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.",
      },
      {
        title:
          "We restrict access of your personal information to those employees of OjirehPrime who have a business reason for knowing such information. We continuously educate and train our employees about the importance of confidentiality and privacy of information. We maintain physical, electronic, and procedural safeguards that comply with the relevant laws and regulations to protect your personal information from unauthorized access.",
      },
      {
        title:
          "We strive to collect no more personal information than is required. This helps reduce the total risk of harm should data loss or a breach of security occur.",
      },
    ],
  },
  {
    title: "Information we collect about you.",
    privacyList: [
      {
        titleWithoutCount:
          "We may request, collect and use personal information about you as follows:",
      },
      {
        contentTitle: `Information you give us... :`,
        privacyList: [
          {
            title:
              "you may give us information about yourself by filling in forms when registering or using our Services or via correspondence e.g. email. This includes information you provide when you register to use our Services, participate in discussion boards or other social media functions on our Website or App, enter a competition, promotion or survey, and when you report a problem with our Services. Additional information you give us for security, identification and verification purposes may include your address, phone number, financial information (including credit card, debit card, or bank account information), payment reason, geographical location, social security/insurance number, national identification number, personal description, photograph, passport and/or National ID. If you fail to provide any of this information, it might affect our ability to provide our Services to you.",
          },
          {
            title:
              "the content of your communications with us, which we collect via telephone call recordings, online chat, emails, direct messaging, and other means. In some cases, including when you send or receive high value or high-volume transactions, or where we need to comply with anti-money laundering regulations, we may also need more identification information from you, including a copy of your bank account statements. You have the right to request access to and correction of personal information that we hold about you.",
          },
          {
            title:
              "in providing the personal information of any individual (other than yourself), including connected persons, to us during your use of our Services, you acknowledge that you either have the appropriate authority to provide such personal information and have obtained prior consent from such individual to disclose his/her personal information to us. You also confirm that you have brought this Policy to their attention if legally necessary, and have received their consent to our collection, use and disclosure of such personal information for the purposes set out in this Policy. The term ‘connected person’ means an individual connected to OjirehPrime through the use of our Services and could be an account holder, payment beneficiary, recipient of a designated payment, guarantor, director, shareholder, partners or members of a partnership, trustee, authorized signatory of a designated account, a friend you have recommended, individuals in your contact list or any other person who has a relevant relationship with OjirehPrime.",
          },
          {
            title:
              "please ensure that your personal information is current, complete, and accurate by logging onto your account and updating it whenever necessary.",
          },
          {
            title:
              " if you believe that one of your contacts has provided us with your personal information and you would like to request that it be removed from our database",
          },

          {
            title:
              "Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Services, you are responsible for keeping this password confidential. We ask you not to share this password with anyone else and not to use this password for other services or products. Your password is stored on OjirehPrime’s servers in an encrypted form. We may however disclose your first name and the first letter of your last name to other users, who are transacting with you via the Services.",
          },
          {
            title:
              "As you will know, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your personal information during transmission; any transmission is at your own risk and outside our control. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.",
          },
          {
            title:
              "We restrict access of your personal information to those employees of OjirehPrime who have a business reason for knowing such information. We continuously educate and train our employees about the importance of confidentiality and privacy of information. We maintain physical, electronic, and procedural safeguards that comply with the relevant laws and regulations to protect your personal information from unauthorized access.",
          },
          {
            title:
              "We strive to collect no more personal information than is required. This helps reduce the total risk of harm should data loss or a breach of security occur.",
          },
        ],
      },
      {
        contentTitle: "Information we collect about you:",
        title: "we may automatically collect the following information:",
        privacyList: [
          {
            title:
              "details of the transactions you carry out when using our Services, including geographic location from which the transaction originates;",
          },
          {
            title:
              "technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system, and platform;",
          },
          {
            title:
              "information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our Website or App (including date and time);products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number; and",
          },
          {
            title:
              "information about your marketing and communication preferences.",
          },
        ],
      },
      {
        contentTitle: "Information we receive from other sources:",
        title:
          "We may receive information about you if you use any other websites we operate or the other services we provide. We are also working closely with third parties and may receive information about you from them. This may include:",

        privacyList: [
          {
            title:
              "the payment service providers you use to transfer money to us;",
          },
          {
            title:
              "the bank whose account you link to your OjirehPrime account for the purpose of satisfying regulatory verification;",
          },
          {
            title: "businesspartners;",
          },
          {
            title:
              "in some jurisdictions, we may check the information you have provided to us with governmental or private identity record databases or with credit reference agencies.",
          },
        ],
      },
      {
        contentTitle: "Information from social media networks:",
        title:
          "If you log into our Services using your social network account (including Apple ID, Google Play Store, Facebook) etc., we will receive relevant information that is necessary for us to enable our Services to authenticate your access. The social network will provide us with access to certain information that you have provided to them, in accordance with its privacy policy. We use such information, together with any other information you directly provide to us when registering or using our Services, to create your account and to communicate with you about the information, products, and services that you request from us.",
      },
      {
        contentTitle: "Sensitive Data:",
        title:
          "As part of our identity verification process, we may collect, use and store biometric data, namely: we may extract face scan information from photos and videos to compare pictures of you on identity documents with each other, to verify your identity and for anti-fraud checks, and to improve these processes. We may ask you to specifically consent to the collection, use and storage of your biometric data during the verification process. We will not disclose or disseminate any biometric data to anyone other than our identity verification providers, or when required by applicable laws, or pursuant to a valid order from a court. We never sell, lease, trade or otherwise benefit from your biometric data. We will retain biometric data for the period necessary to complete the verification process, unless otherwise required by law or legal process.",
      },
      {
        contentTitle: "Children’s Data:",
        title:
          "Children’s Data Our products and services are directed at adults and are not intended for children. We therefore do not knowingly collect data from children. Any data collected from a child before their age is determined will be deleted.",
      },
    ],
  },
  {
    title: "Cookies",
    privacyList: [
      {
        title:
          "Our Services use cookies to remember users’ settings, for authentication purposes and to build an ongoing picture of each user’s needs and preferences. This distinguishes you from other users as we are able to tailor the Services to meet your needs and enhance your experience of the Services.",
      },
      {
        title:
          "You may control the use of cookies at the individual browser level by changing your browser settings. If you reject cookies, you may still use our Services but your ability to use some features or areas of our Website and/or App may be limited.",
      },
    ],
  },
  {
    title: "How we use your personal information",
    privacyList: [
      {
        contentTitle: "Lawful basis",
        title:
          "We rely on the following legal basis to process your information:",
        privacyList: [
          {
            title:
              "to enable you carry our digital banking operations and have access to use our products under the Services;",
          },
          {
            title:
              "to carry out our obligations relating to your contract with us for provision of digital banking services;",
          },
          {
            title:
              "to comply with any applicable legal and/or regulatory requirements, upon demonstration of lawful authority, and to comply with court orders;",
          },
          {
            title:
              "to prevent and detect crimes, including fraud and financial crimes;",
          },
          {
            title:
              "to notify you about changes and send you other administrative information;",
          },
          {
            title:
              "as part of our efforts to keep our Services safe and secure;",
          },
          {
            title:
              "to administer our Services and for internal operational, planning, audit, troubleshooting, data analysis, testing, research, statistical and survey purposes;",
          },
          {
            title: "to undertake system or product development;",
          },
          {
            title:
              "to take steps to recover amounts owed to us, and to allow us to pursue available remedies or limit damages that we may sustain; and",
          },
          {
            title:
              "to allow a third party or a financial institution that sent money to recover money received by you in error or due to fraud.",
          },
        ],
      },
    ],
  },
  {
    title: "Choice/Opt- Out",
    privacyList: [
      {
        title:
          "We may provide you or permit selected third parties to provide you with newsletters or information via email about other similar products and services we feel may interest you. You may choose to stop receiving such information by following the unsubscribe instructions",
      },
      {
        title:
          "We will also send you service-related email announcements on rare occasions when it is necessary to do so. For instance, if our service is temporarily suspended for maintenance, we might send you an email. You do not have an option to opt out of these emails, which are not promotional in nature.",
      },
      {
        title:
          "We may partner with a third party to either display advertising on our Website or to manage our advertising on other sites. Our third-party partner may use technologies such as cookies to gather information about your activities on our Website and/or App.",
      },
    ],
  },
  {
    title: "Disclosure of your personal information",
    privacyList: [
      {
        title:
          "We may share your personal information with selected third parties that provide services which help us enhance our business activities or customer service including:",
        privacyList: [
          {
            title:
              "affiliates, business partners, suppliers and sub-contractors in connection with the provision of the Services;",
          },
          {
            title:
              "advertisers and advertising networks solely to select and serve relevant adverts to you and others; and",
          },
          {
            title:
              "analytics and search engine providers that assist us in the improvement and optimisation of our Website.",
          },
        ],
      },
      {
        title: "We may disclose your personal information to third parties",
        privacyList: [
          {
            title:
              "in the event that we are involved in a merger, acquisition or sale of all or any portion of our business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets;",
          },
          {
            title:
              "if you request a service or product that is administered by a third party, in order to allow us to respond to the request. If such third party is working for OjirehPrime, it may also send back to OjirehPrime any new information obtained from you;",
          },
          {
            title:
              "if we are under a duty to disclose or share your personal information in order to comply with any legal obligation, or in order to enforce or apply contract with you; or to protect the rights, property, or safety of OjirehPrime and its Services, our subscribers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction;",
          },
          {
            title:
              "to assist us in conducting or co-operating in investigations of fraud or other illegal activity where we believe it is reasonable and appropriate to do so;",
          },
          {
            title:
              "to prevent and detect fraud or crime. We reserve the right to report to law enforcement agencies any activities that we believe in good faith are unlawful including exchanging information with other companies, organizations and agencies for the purposes of fraud protection;",
          },
          {
            title:
              "in response to a subpoena, warrant, court order, or as otherwise required by law;",
          },
          {
            title: "to assess financial and insurance risks;",
          },
          {
            title: "to recover debt or in relation to your insolvency; and",
          },
          {
            title: "to develop customer relationships, services and systems.",
          },
        ],
      },
    ],
  },
  {
    title: "Where we store your personal information",
    privacyList: [
      {
        titleWithoutCount:
          "The personal information that we collect from you may be transferred to, and stored at, a destination outside Nigeria or the United Kingdom. It may also be processed by staff operating outside these jurisdictions, who work for us or for one of our suppliers. Such staff may be engaged in, among other things, the fulfillment of your payment order, the processing of your payment details and the provision of support services. By submitting your personal information, you agree to this transfer, storing or processing. We take all steps reasonably necessary to ensure that your personal information is treated securely and in accordance with this Policy.",
      },
    ],
  },
  {
    title: "Links to other websites",
    privacyList: [
      {
        titleWithoutCount:
          "Our Services may, from time to time, contain links to and from the websites of our partner networks, advertisers, and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility for them. Please check these policies before you submit any personal information to these websites.",
      },
    ],
  },
  {
    title: "Social Media Widgets",
    privacyList: [
      {
        titleWithoutCount:
          "Our Website may include social media widgets or features, such as the Facebook Like button and Twitter button which are interactive mini-programs that run on our Website to provide specific services from another company (e.g. displaying the news, opinions, music, etc.) Personal information, such as your email address, may be collected through the widget. Cookies may also be set by the widget to enable it to function properly. Information collected by this widget is governed by the privacy policy of the company that created it.",
      },
    ],
  },
  {
    title: "Testimonials",
    privacyList: [
      {
        title:
          "We display personal testimonials of satisfied customers on our Website in addition to other endorsements, where you have agreed with a third party review site that we may do so. You may update or delete your testimonial at any time.",
      },
    ],
  },
  {
    title: "Access to information",
    privacyList: [
      {
        title:
          " Subject to applicable laws, you may have the right to access information we hold about you. Your right of access can be exercised in accordance with the relevant data protection legislation. You may request to review, delete or update your personal information to ensure it is accurate at any time. Any access request may be subject to a fee to meet our costs in providing you with details of the information we hold about you.",
      },
      {
        title:
          "We will retain your information for as long as your account is active or as needed to provide you the Services, including a record of your transactions on our Website or carried out on the App. If you wish to cancel your account or request that we no longer use your information to provide you with the Services, please contact us at privacy@ojirehprime.com . We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our contract with you and will securely delete your information when we no longer have a need to retain it.",
      },
    ],
  },
  {
    title: "Changes to our privacy policy",
    privacyList: [
      {
        titleWithoutCount:
          "We may update this Policy to reflect changes to our information practices. Any changes we make to this Policy will be posted on this page and if we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this Website or via a link from your App prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.",
      },
    ],
  },
  {
    title: "Notification in the event of breach",
    privacyList: [
      {
        titleWithoutCount:
          "It would be very unlikely, but if a breach in the security of personal information were to occur, we will notify you if you are actually or potentially affected. In this event we will contact you via the email address you provided or publication on OjirehPrime’s website. We reserve the right to delay notification if this is requested by law enforcement or other authorities, or if giving notice immediately will increase the risk of harm to end-users overall.",
      },
    ],
  },
  {
    title: "Contact",

    privacyList: [
      {
        title:
          "Questions, comments and requests regarding this Policy are welcome and should be addressed to privacy@ojirehprime.com (indicating ‘Privacy Request’ in the message subject).",
      },
      {
        title:
          "If you contact us by e-mail, we may keep a record of your correspondence and ask for your name and contact information in order to send you a reply.",
      },
    ],
  },
];
