import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { AccordionsDiv } from "../../Styles/ComponentStyles/faq/style/accordions.style";

const Accordions = () => {
  return (
    <AccordionsDiv>
      <div className="accordion_wrapper">
        <Accordion activeIndex={0} style={{ width: "100%" }}>
          <AccordionTab
            header="Is Olijeh a Licensed bank?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              While we work on perfecting our License, all savings account are
              issued by Solid Allianze Microfinance Bank Limited.
            </p>
          </AccordionTab>
          <AccordionTab
            header="How do i get an account?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              Our Mobile app is available on the Google Playstore and Apple IOS
              as OjirehPrime.
            </p>
          </AccordionTab>
          <AccordionTab
            header="Is my deposit safe with Olijeh?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              it is a regulatory requirement that all depositors funds must be
              insured by the Nigerian deposit insurance corporation which
              applies to your deposit...
            </p>
          </AccordionTab>

          <AccordionTab
            header="What fees do you charge?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              98% of 100,000 millennials we interviewed agreed that transperency
              of charges is a priority, so we’ve prepared a detailed write up
              breaking down our charges with a promise that we won't do
              otherwise...
            </p>
          </AccordionTab>

          <AccordionTab
            header="Do you offer joint accounts?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              At the moment the answer is no Joint account, but we have
              innovated a more creative way to save money in pairs or groups...
            </p>
          </AccordionTab>

          <AccordionTab
            header="Are your credit cards free?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              Our credit cards are not just free, we will also deliver it to
              your doorstep for free. Expect your cards between 24 to 72hrs
              depending on your location. Read through our delivery timelines
              across the country to know when to expect your cards...
            </p>
          </AccordionTab>

          <AccordionTab header="How about loans?" style={{ width: "100%" }}>
            <p className="m-0">
              Yes we offer loans with very reasonable interest rates. We have a
              detailed break down of the kind of loans we offer.
            </p>
          </AccordionTab>

          <AccordionTab
            header="Do you have a savings system?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              Savings should be flexible, innovative and rewarding. We combined
              these three factors to build products that would encaurage you to
              save more...
            </p>
          </AccordionTab>

          <AccordionTab
            header="What is the minimum balance?"
            style={{ width: "100%" }}
          >
            <p className="m-0">Minimum balance is zero.</p>
          </AccordionTab>

          <AccordionTab
            header="How do i make contact if i need help?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              If you want to reach out, you can send a mail via
              support@ojirehprime.com or chat with us directly via the website,
              call us on 01-4539903 or send a whatsapp chat via 09069144499. For
              in Person Meetings, You can walk into any of our offices.
            </p>
          </AccordionTab>

          <AccordionTab
            header="What if i misplace my Phone or Card?"
            style={{ width: "100%" }}
          >
            <p className="m-0">
              We have made provision for a back end access to your account from
              the website that gives you the opportunity to freeze your account
              and ensure there is no unauthorised transactions...
            </p>
          </AccordionTab>
        </Accordion>
      </div>
    </AccordionsDiv>
  );
};

export default Accordions;
