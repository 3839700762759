import styled from "styled-components";

export const TermsAndConditionDetailsDiv = styled.div`
  width: 100%;
  padding: 90px 200px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .privacy_state {
    margin: 0;
    font-size: 16px;
    color: #000000;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 200%;
  }

  .privacy_content {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .privacy_content_title {
      margin: 0;
      font-size: 18px;
      color: #000000;
      font-family: "Inter", sans-serif;
      font-weight: 800;
      line-height: 200%;
    }

    .privacy_subContent {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-left: 40px;

      .privacy_subContent_heading {
        margin: 0;
        font-size: 16px;
        color: #000000;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        line-height: 200%;
      }

      .privacy_subContent_List {
        margin: 0;
        font-size: 16px;
        color: #000000;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        line-height: 200%;

        .privacy_subContent_List_title {
          margin: 0;
          font-size: 16px;
          color: #000000;
          font-family: "Inter", sans-serif;
          font-weight: 700;
          line-height: 200%;
        }
      }

      .privacy_subContent_List_conatiner {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .privacy_subContent_List_sub_container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-left: 40px;

          .privacy_subContent_List_sub {
            margin: 0;
            font-size: 15px;
            color: #000000;
            font-family: "Inter", sans-serif;
            font-weight: 400;
            line-height: 200%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    padding: 90px 100px;
  }

  @media screen and (max-width: 740px) {
    padding: 90px 50px;
  }

  @media screen and (max-width: 500px) {
    padding: 90px 20px;
  }
`;
