import React from "react";
import AppImage from "../../../Assets/App-Store-Symbol.e35c0a68.png";
import AndroidImage from "../../../Assets/playstorehere.7463dad0.png";
import CoverImage from "../../../Assets/dashboard.png";
import { HeroSectionStyle } from "../../../Styles/ComponentStyles/LandingPageComponentStyles/heroSection.style";

const HeroSection = ({ header, content, checkLead }) => {
  return (
    <HeroSectionStyle>
      <div className="hero_Wrapper">
        <div className="hero_head">
          <div className="hero_head_title">
            <h1>{header}</h1>
            <p>{content}</p>
          </div>
          {checkLead && (
            <div className="hero_head_images">
              <img src={AppImage} /> <img src={AndroidImage} />
            </div>
          )}
        </div>
        <div className="hero_preview">
          <img src={CoverImage} />
        </div>
      </div>
    </HeroSectionStyle>
  );
};

export default HeroSection;
