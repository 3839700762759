import styled from "styled-components";

export const HeroSectionStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #27cf10;

  .hero_Wrapper {
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hero_head {
      width: 45%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .hero_head_title {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin: 0px;
        padding: 0px;

        h1 {
          color: #ffffff;
          font-size: 52px;
          font-family: "Inter", sans-serif;
          font-weight: 700;
          margin: 0;

          @media screen and (max-width: 1050px) {
            font-size: 45px;
          }

          @media screen and (max-width: 840px) {
            font-size: 32px;
          }
        }

        p {
          margin: 0;
          color: #ffffff;
          font-size: 20px;
          font-family: "Inter", sans-serif;
          font-weight: 400;
          line-height: 28px;

          /* @media screen and (max-width: 740px) {
            font-size: 16px;
          } */
        }
      }

      .hero_head_images {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 35%;
        }
      }

      @media screen and (max-width: 740px) {
        width: 100%;
      }
    }

    .hero_preview {
      width: 30%;

      img {
        width: 100%;

        @media screen and (max-width: 740px) {
          width: 50%;
        }
      }

      @media screen and (max-width: 740px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    @media screen and (max-width: 740px) {
      width: 95%;
      flex-direction: column;
      gap: 40px;
      padding-top: 120px;
      justify-content: unset;
    }
  }

  @media screen and (max-width: 740px) {
    height: 140vh;
    align-items: unset;
  }

  @media screen and (max-width: 530px) {
    height: 130vh;
  }

  @media screen and (max-width: 414px) {
    height: 120vh;
  }
`;
