import styled from "styled-components";

export const GeneralSubHeroSectionDiv = styled.div`
  height: 400px;
  width: 100%;
  background-color: #4b034c;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 64px;
    color: #ffffff;
    margin: 0;
    text-align: center;

    @media screen and (max-width: 500px) {
      font-size: 32px;
    }
  }
`;
