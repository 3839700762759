const { default: styled } = require("styled-components");

export const CardStyledDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 130px 0px;

  .card_wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${({ indicatior }) => indicatior && "row-reverse"};

    .card_wrapper_image_section {
      width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 45%;
      }

      @media screen and (max-width: 990px) {
        width: 48%;
      }

      @media screen and (max-width: 840px) {
        width: 50%;
      }

      @media screen and (max-width: 740px) {
        width: 100%;
      }
    }

    .card_wrapper_content_wrapper {
      width: 45%;

      .card_wrapper_content {
        width: 85%;
        display: flex;
        flex-direction: column;
        gap: 18px;

        .card_wrapper_content_header {
          display: flex;
          flex-direction: column;
          gap: 2px;

          h4 {
            margin: 0;
            color: #0d730b;
            font-size: 16px;
            font-family: "Inter", sans-serif;
            font-weight: 600;
          }

          h3 {
            margin: 0;
            color: #363636;
            font-size: 32px;
            font-family: "Inter", sans-serif;
            font-weight: 700;
          }
        }

        .card_wrapper_content_paragraph {
          p {
            margin: 0;
            color: #4a4a4a;
            font-size: 20px;
            font-family: "Inter", sans-serif;
            font-weight: 400;
          }
        }

        @media screen and (max-width: 1080px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 990px) {
        width: 48%;
      }

      @media screen and (max-width: 840px) {
        width: 50%;
      }

      @media screen and (max-width: 740px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 740px) {
      width: 95%;
      /* flex-direction: column; */
      flex-direction: ${({ indicatior }) =>
        indicatior ? "column-reverse" : "column"};
      justify-content: unset;
      gap: 50px;
    }
  }

  @media screen and (max-width: 740px) {
    padding: 90px 0px;
  }
`;
