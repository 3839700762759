import React from "react";
import { FooterStyledDiv } from "../../Styles/ComponentStyles/FooterStyles/footer.style";
import AppImage from "../../Assets/App-Store-Symbol.e35c0a68.png";
import AndroidImage from "../../Assets/playstorehere.7463dad0.png";
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <FooterStyledDiv>
      <div className="footer_wrapper">
        <div className="footer_header">
          <h3>Global banking for Africa's Gen Z.</h3>
          <div className="footer_header_images">
            <img src={AppImage} /> <img src={AndroidImage} />
          </div>
        </div>
        <div className="footer_body">
          <div className="footer_body_title">
            <p>
              Banking services offered by Solid Allianze Microfinance Bank
              (RC1103816) with registered address at 12, Montgomery road, off
              Herbert Macaulay, yaba. Solid Allianze Microfinance Bank is
              licensed by the Central Bank of Nigeria. Deposits are insured by
              the Nigerian Deposit Insurance Corporation (NDIC).
            </p>
          </div>
          <div className="footer_body_link">
            <div className="footer_body_section">
              <div className="footer_body_section_header">
                <h4>About Us</h4>
              </div>
              <div className="footer_body_section_content">
                <NavLink to={"/faq"}>
                  <p>FAQs</p>
                </NavLink>

                <NavLink to={"/about"}>
                  <p>Who we are</p>
                </NavLink>

                <NavLink to={"/term_condition"}>
                  <p>Terms and Conditions</p>
                </NavLink>
              </div>
            </div>
            <div className="footer_body_section">
              <div className="footer_body_section_header">
                <h4>Support</h4>
              </div>
              <div className="footer_body_section_content">
                <NavLink to={"/contact"}>
                  <p>Contact Us</p>
                </NavLink>

                <NavLink to={"/Security"}>
                  <p>Security Data</p>
                </NavLink>

                <NavLink to={"/privacy"}>
                  <p>Privacy Notice</p>
                </NavLink>
              </div>
            </div>
            {/* <div className="footer_body_section">
              <div className="footer_body_section_header">
                <h4>Company</h4>
              </div>
              <div className="footer_body_section_content">
                <p>Social Impact</p>
                <p>Careers</p>
                <p>Transparency Dashboard</p>
                <p>Privacy Notice</p>
              </div>
            </div> */}

            <div className="footer_body_section">
              <div className="footer_body_section_header">
                <h4>Social Media</h4>
              </div>
              <div className="footer_body_section_content">
                <div className="footer_body_section_content_item">
                  <div className="footer_body_section_content_item_icon_body">
                    <BsFacebook className="footer_body_section_content_item_icon" />
                  </div>
                  <p>Facebook</p>
                </div>
                <div className="footer_body_section_content_item">
                  <div className="footer_body_section_content_item_icon_body">
                    <BsLinkedin className="footer_body_section_content_item_icon" />
                  </div>
                  <p>Linkedin</p>
                </div>
                <div className="footer_body_section_content_item">
                  <div className="footer_body_section_content_item_icon_body">
                    <BsTwitter className="footer_body_section_content_item_icon" />
                  </div>
                  <p>Twitter</p>
                </div>
                <div className="footer_body_section_content_item">
                  <div className="footer_body_section_content_item_icon_body">
                    <BsInstagram className="footer_body_section_content_item_icon" />
                  </div>
                  <p>Instagram</p>
                </div>
              </div>
            </div>

            <div className="footer_body_section">
              <div className="footer_body_section_header">
                <h4>Contact</h4>
              </div>
              <div className="footer_body_section_content">
                <div className="footer_body_section_content_item">
                  <div className="footer_body_section_content_item_icon_body">
                    <MdEmail className="footer_body_section_content_item_icon" />
                  </div>
                  <p>support@ojirehprime.com</p>
                </div>
                <div className="footer_body_section_content_item">
                  <div className="footer_body_section_content_item_icon_body">
                    <MdLocationPin className="footer_body_section_content_item_icon" />
                  </div>
                  <p>3rd Floor 86-90 Paul Street London EC2A 4NE</p>
                </div>
                <div className="footer_body_section_content_item">
                  <div className="footer_body_section_content_item_icon_body">
                    <FaWhatsapp className="footer_body_section_content_item_icon" />
                  </div>
                  <p>+234 906 914 4499</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_footer">
          <p>© Olijeh Financial Services Limited 2023</p>
        </div>
      </div>
    </FooterStyledDiv>
  );
};

export default Footer;
