import React from "react";
import HeroSection from "../Components/LandingPageComponent/HeroSection";
import Accordions from "../Components/faqs/accordions";

const Faq = () => {
  return (
    <div>
      <HeroSection
        header={"FAQs"}
        content={
          "We have collated together our top most frequently asked questions in hope that all or one of them would provide you the answer you are looking for. If you can not find the right answers here, do not hesitate to "
        }
        checkLead={false}
      />

      <Accordions />
    </div>
  );
};

export default Faq;
