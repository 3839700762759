import LandingPage from "./Screens/landingPage";
import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import { Route, Routes } from "react-router-dom";
import Faq from "./Screens/faq";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Aboutus from "./Screens/about_us";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel";
import "./App.css";
import PrivacyPolicy from "./Screens/privacyPolicy";
import TermAndCondition from "./Screens/termAndCondition";
import Security from "./Screens/security";
import Contactus from "./Screens/contactus";

function App() {
  return (
    <div>
      <Nav />

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/term_condition" element={<TermAndCondition />} />
        <Route path="/Security" element={<Security />} />
        <Route path="/contact" element={<Contactus />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
