import React from "react";
import HeroSection from "../Components/LandingPageComponent/HeroSection";
import CardStyle from "../Components/LandingPageComponent/cardStyle";
import PhoneOne from "../Assets/Tranfer.png";
import PhoneTwo from "../Assets/BOXES.a1e27781.png";
import PhoneThree from "../Assets/account-manager.7f9f627c.png";
import PhoneFour from "../Assets/Utilitybillspage.png";
import PhoneFive from "../Assets/loan.png";
import PhoneSix from "../Assets/tsl.png";
import PhoneSeven from "../Assets/payschoolfees.png";
import PhoneEight from "../Assets/LoanAccess.png";
import PhoneNine from "../Assets/scholarship.png";

const LandingPage = () => {
  return (
    <div>
      <HeroSection
        header={
          "A financial institution providing comprehensive FinTech solutions in Africa."
        }
        content={
          "A more customer-focused banking experience with seamless financial products for your everyday needs."
        }
        checkLead={true}
      />

      <CardStyle
        image={PhoneOne}
        header={"SEAMLESS TRANSACTIONS"}
        subHeader={"Our transfers are free"}
        content={`With our free transfer service, you can send money to any bank
        account in Nigeria without worrying about incurring extra
        charges. We understand that every naira counts, and that's why
        we've eliminated the hidden fees. Olijeh ensures your
        hard-earned money reaches its destination without any
        deductions.`}
      />

      <CardStyle
        image={PhoneEight}
        indicatior={true}
        wrappStyle={{ backgroundColor: "#E7FEF7" }}
        header={"TURNING BORROWERS INTO ADVOCATES"}
        subHeader={"We prioritize the needs of borrowers."}
        content={`
        At Olijeh Loans, we are committed to cultivating opportunities and turning borrowers into advocates by providing hope and empowerment, especially for those seeking financial support for school fees. We understand the challenges families face in ensuring quality education for their children. Our dedicated loan advisors provide personalized solutions, easing the financial burden for students and offering a supportive hand to parents facing challenges in financing their children's education. Join us on this transformative journey where financial empowerment meets the dreams of a better future for you and your family.`}
      />

      <CardStyle
        image={PhoneSeven}
        header={"CULTIVATING OPPORTUNITIES THROUGH SCHOOL FEES SUPPORT"}
        subHeader={"Empowering Dreams with Tailored Financial Solutions."}
        content={`
        At Olijeh Loans, we firmly believe in the transformative impact of human connections. Our commitment extends beyond providing financial solutions. We understand that life's journey involves various needs, including educational pursuits. Therefore, our dedicated loan advisors are here to comprehend your unique requirements. Whether you're seeking financial support for loans to pay school fees, or if schools themselves require assistance, such as loans to import necessary resources, we are ready to offer personalized solutions and unwavering support at every stage of your journey. Our mission is to facilitate the growth and prosperity of individuals and institutions alike, fostering a community where financial challenges are met with tailored solutions.`}
      />

      <CardStyle
        wrappStyle={{ backgroundColor: "#E7FEF7" }}
        indicatior={true}
        image={PhoneNine}
        header={"Empowering Students with Pathways to Success"}
        subHeader={"Empowering Growth through Tailored Financial Support"}
        content={`At Olijeh Loans, we go beyond providing financial support for students by offering a unique opportunity – those who collect loans stand the chance of unlocking scholarship opportunities. We understand the importance of education and are committed to empowering students to achieve their dreams. Our loans not only ease the financial burden of academic pursuits but also open doors to potential scholarships, providing an extra layer of support for those aspiring to excel. Join us on this transformative journey where financial empowerment becomes a stepping stone to educational success..`}
      />

      <CardStyle
        image={PhoneEight}
        header={
          "UNLOCKING POSSIBILITIES: LOANS AND GRANTS FOR EDUCATIONAL INSTITUTIONS"
        }
        subHeader={"Empowering Growth through Tailored Financial Support"}
        content={`At Olijeh Loans, we specialize in unlocking possibilities for educational institutions by providing tailored loans and grants. Whether your school needs financial support for upkeep, acquiring essential facilities, or enhancing university departments and faculties, we are here to cultivate growth. Our dedicated advisors comprehend the unique needs of educational institutions, offering personalized solutions to foster development. Join us in this transformative journey, where financial empowerment meets the aspirations of creating a thriving educational environment.`}
      />

      <CardStyle
        wrappStyle={{ backgroundColor: "#E7FEF7" }}
        indicatior={true}
        image={PhoneFour}
        header={"ALL YOUR BILLS IN ONE PLACE"}
        subHeader={"Pay bills with ease"}
        content={`Olijeh lets you enjoy the simplicity of paying your bills whether it's for airtime, data bundles, cable TV subscriptions, or power bills, you can easily pay your bills using our cutting-edge digital banking platform. Manage your spending more easily than ever with Olijeh.`}
      />

      <CardStyle
        image={PhoneThree}
        header={"TURNING CUSTOMERS TO AMBASSADORS"}
        subHeader={"We are customer focused"}
        content={`At Olijeh, we believe in the power of human connections. This is why we assign dedicated account managers to understand your needs and guide you through your financial journey with tailored solutions backed with exceptional support every step of the way.`}
      />

      <CardStyle
        wrappStyle={{ backgroundColor: "#E7FEF7" }}
        indicatior={true}
        image={PhoneTwo}
        header={"AN INNOVATIVE WAY TO SAVE"}
        subHeader={"Introducing Boxes"}
        content={`Boxes are innovative ways designed to assist you in saving and investing your money effectively. These boxes include the investment saving box, fixed savings box, flexible saving box, and interest-free saving box, each product feature tailored to suit specific customer’s needs. With these various boxes, achieving your desired dream purchase is now within reach.`}
      />

      <CardStyle
        image={PhoneTwo}
        header={"ALL YOUR THRIFT SAVINGS IN ONE PLACE"}
        subHeader={"Manage your thrift savings with ease."}
        content={`Olijeh allows you to experience the convenience of managing your thrift savings effortlessly. Whether you're saving for a rainy day, retirement, or any financial goal, you can efficiently manage your thrift savings through our advanced digital banking platform. Take control of your financial future with Olijeh.`}
      />
    </div>
  );
};

export default LandingPage;
